import request from '@/axios'
import type { DataList, UserType } from './types'

interface RoleParams {
  roleName: string
}

export const loginApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/mock/user/login', data })
}

// export const loginOutApi = (data: { token: string }): Promise<IResponse> => {
//   console.log('🚀 ~ loginOutApi ~ data:', data)
//   return request.get({ url: '/mock/user/loginOut' })
// }

export const loginOutApi = (data: { token: string }): Promise<IResponse> => {
  return request.post({ url: '/api/admin/logout', data })
}

export const getAdminRoleApi = (
  params: RoleParams
): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/mock/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2', params })
}

export const getTestFunction = (params: object): Promise<IResponse<UserType>> => {
  return request.get({ url: '/api/test', params })
}

export const getTestFunction2 = (params: object): Promise<IResponse<UserType>> => {
  return request.post({ url: '/api/test2', data: params })
}

export const getImg = (params: { page: number; size: number }): Promise<IResponse<DataList>> => {
  return request.get({ url: '/mock/imgs', params })
}

export const login = (data: any): Promise<IResponse> => {
  return request.post({ url: '/api/admin/login', data })
}
