import CryptoJs from 'crypto-js'
import tripledes from 'crypto-js/tripledes'
import { isEmpty } from '@/utils/is'

const key = 'HONGKANGLIHE'
const disable = import.meta.env.VITE_USE_CRYPTO

// 加密白名单
export const whiteList = [
  '/mock/user/login',
  '/mock/role/list',
  // '/api/test2',
  // '/api/test',
  '/mock/role/list2',
  '/upload/upload'
]

// 判断是否在白名单内
export const isWhiteList = (whiteList: string[], path: string): boolean => {
  return whiteList.some((item) => {
    return path.indexOf(item) > -1
  })
}

//  加密 | post
export const encrypt = (data: object | string): string | object => {
  if (disable !== 'true' || isEmpty(disable)) return data
  return tripledes.encrypt(JSON.stringify(data), key).toString()
}

// 解密
export const decrypt = (data: string | object): object => {
  if (typeof data === 'object') return data
  const res = tripledes.decrypt(data, key).toString(CryptoJs.enc.Utf8)
  return JSON.parse(res)
}
