import { AxiosResponse, InternalAxiosRequestConfig } from './types'
import { ElMessage } from 'element-plus'
// import qs from 'qs'
// import { SUCCESS_CODE, TRANSFORM_REQUEST_DATA } from '@/constants'
import { SUCCESS_CODE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
// import { objToFormData } from '@/utils'
import { isWhiteList, whiteList, encrypt, decrypt } from '@/crypto'

const defaultRequestInterceptors = (config: InternalAxiosRequestConfig) => {
  if (config.method === 'post') {
    // if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    //   config.data = qs.stringify(config.data)
    // } else if (TRANSFORM_REQUEST_DATA && config.headers['Content-Type'] === 'multipart/form-data') {
    //   config.data = objToFormData(config.data)
    // }
    if (config.data) {
      if (!isWhiteList(whiteList, config.url as string)) {
        // 是否包含token
        // 预留接口，为token做准备
        // if (data.hasOwnProperty('token')) {
        // }
        const data = { params: encrypt(config.data) }
        config.data = data
      }
    }
  } else {
    if (config.params) {
      const url = config.url as string
      // * 截断请求后进行加密重新拼装
      // * 1.判断接口是否在白名单
      // * 2.进行加密拼装获取不做处理
      const encryptData = encrypt(config.params)
      if (isWhiteList(whiteList, url) || typeof encryptData === 'object') {
        let url = config.url as string
        url += '?'
        const keys = Object.keys(config.params)
        for (const key of keys) {
          if (config.params[key] !== void 0 && config.params[key] !== null) {
            url += `${key}=${encodeURIComponent(config.params[key])}&`
          }
        }
        url = url.substring(0, url.length - 1)
        config.params = {}
        config.url = url
      } else {
        config.url = `${url}?params=${encryptData}`
      }
      config.params = {}
    }
  }
  return config
}

const defaultResponseInterceptors = (response: AxiosResponse) => {
  const config = response?.config
  const res = response?.data
  if (config.responseType === 'blob') {
    // 如果是文件流，直接过
    return response
  } else if (res.code === SUCCESS_CODE) {
    // 解密
    // 判断是否在白名单内
    if (config.method === 'get') {
      const url = config.url?.split('?')[0]
      if (!isWhiteList(whiteList, url as string)) {
        res.data = decrypt(res.data)
      }
    } else {
      if (!isWhiteList(whiteList, config.url as string)) {
        res.data = decrypt(res.data)
      }
    }
    return res
  } else {
    if (config.url?.includes('baidubce.com')) {
      return res
    }
    ElMessage.error(res.message)
    if (res.code === 401) {
      const userStore = useUserStoreWithOut()
      userStore.logout()
    }
  }
}

export { defaultResponseInterceptors, defaultRequestInterceptors }
