import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/adminAccount/index',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/adminAccount',
    component: Layout,
    redirect: '/adminAccount',
    name: '管理员',
    meta: {
      title: '管理员账号',
      icon: 'carbon:user-admin',
      alwaysShow: true
    },
    children: [
      {
        path: 'index',
        name: '管理员账号',
        component: () => import('@/views/AdminAccount/index.vue'),
        meta: {
          title: '管理员账号',
          icon: 'carbon:user-admin',
          affix: true
        }
      }
    ]
  },
  {
    path: '/material',
    component: Layout,
    redirect: '/material',
    name: '耗材管理',
    meta: {
      title: '耗材管理',
      icon: 'iconoir:consumable'
    },
    children: [
      {
        path: 'list',
        name: '耗材列表',
        component: () => import('@/views/material/materialList.vue'),
        meta: {
          title: '耗材列表',
          icon: 'lsicon:list-filled'
        }
      },
      {
        path: 'class',
        name: '耗材分类',
        component: () => import('@/views/material/materialCategory.vue'),
        meta: {
          title: '耗材分类',
          icon: 'eos-icons:product-classes'
        }
      }
    ]
  },
  {
    path: '/master',
    component: Layout,
    redirect: '/master',
    name: '维修人员',
    meta: {
      title: '维修人员管理',
      icon: 'arcticons:pokemon-masters-ex-alt-2'
    },
    children: [
      {
        path: 'list',
        name: '维修人员管理',
        component: () => import('@/views/Master/index.vue'),
        meta: {
          title: '维修人员管理',
          icon: 'arcticons:pokemon-masters-ex-alt-2'
        }
      }
    ]
  },
  {
    path: '/platform',
    component: Layout,
    redirect: '/platform',
    name: '平台管理',
    meta: {
      title: '平台管理',
      icon: 'ep:platform'
    },
    children: [
      {
        path: 'carousel',
        name: '轮播图管理',
        component: () => import('@/views/Platform/Carousel.vue'),
        meta: {
          title: '轮播图管理',
          icon: 'openmoji:carousel-horse'
        }
      },
      {
        path: 'kingkong',
        name: '金刚区管理',
        component: () => import('@/views/Platform/KingKong.vue'),
        meta: {
          title: '金刚区管理',
          icon: 'mdi:gold'
        }
      },
      {
        path: 'global',
        name: '全局设置',
        component: () => import('@/views/Platform/GlobalConfig.vue'),
        meta: {
          title: '全局设置',
          icon: 'hugeicons:global-education'
        }
      }
    ]
  },
  {
    path: '/service',
    component: Layout,
    redirect: '/service',
    name: '服务管理',
    meta: {
      title: '服务管理',
      icon: 'hugeicons:service'
    },
    children: [
      {
        path: 'list',
        name: '服务',
        component: () => import('@/views/ServiceManage/index.vue'),
        meta: {
          title: '服务管理',
          icon: 'hugeicons:service'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
